import * as process from 'process';

export const config = {
  NEXT_PUBLIC_SENDINBLUE_API_KEY:
    process.env.NEXT_PUBLIC_SENDINBLUE_API_KEY || '',
  NEXT_PUBLIC_CONTENTFUL_SPACE_ID:
    process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID || '',
  NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN:
    process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN || '',
  NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_TOKEN:
    process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_TOKEN || '',
  NEXT_PUBLIC_CONTENTFUL_MANAGEMENT_TOKEN:
    process.env.NEXT_PUBLIC_CONTENTFUL_MANAGEMENT_TOKEN || '',
  CONTENTFUL_REVALIDATE_SECRET: process.env.CONTENTFUL_REVALIDATE_SECRET || '',
  NEXT_PUBLIC_GOOGLE_ANALYTICS_PROPERTY_ID:
    process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_PROPERTY_ID || '',
  NEXT_PUBLIC_GOOGLE_CLIENT_EMAIL:
    process.env.NEXT_PUBLIC_GOOGLE_CLIENT_EMAIL || '',
  NEXT_PUBLIC_GOOGLE_CLIENT_ID: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || '',
  NEXT_PUBLIC_GOOGLE_PRIVATE_KEY:
    process.env.NEXT_PUBLIC_GOOGLE_PRIVATE_KEY || '',
  BASE_URL: process.env.BASE_URL || '',
  NEXT_PUBLIC_SENTRY_TOKEN: process.env.NEXT_PUBLIC_SENTRY_TOKEN || '',
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID:
    process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || '',
};
