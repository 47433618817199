export const pageview = async (url: string) => {
  //@ts-ignore
  await window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
    page_path: url,
  });
};

export const gtmVirtualPageView = (rest) => {
  // @ts-ignore
  window.dataLayer?.push({
    event: 'VirtualPageView',
    ...rest,
  });
};
export const event = async ({ action, category, label, value }) => {
  //@ts-ignore
  await window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
};
